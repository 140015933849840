@import "../variables.scss";

// Colors
$label-color: #ccc;
$toggle-color: $global-primary-background;
$white: #fff;

// Styles
.toggle-switch {
  position: relative;
  width: 65px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  height: 21px;
  flex: none;

  &-checkbox {
    display: none;
  }

  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-color;
    border-radius: 20px;
    margin: 0;
    height: 21px;
  }

  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 21px;
      padding: 0;
      font: normal normal normal 13px/21px Noto Sans;
      color: white;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 5px;
      background-color: $toggle-color;
      color: $white;
    }
  }

  &-disabled {
    background-color: $label-color;
    cursor: not-allowed;

    &:before {
      background-color: $label-color;
      cursor: not-allowed;
    }
  }

  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 5px;
    background-color: $label-color;
    color: $white;
    text-align: right;
  }

  &-switch {
    display: block;
    width: 14px;
    margin: 3px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 21px);
    border: 0 solid $label-color;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }

    .toggle-switch-switch {
      right: 0;
    }
  }

  &.small-switch {
    width: 40px;

    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }

    .toggle-switch-switch {
      width: 16px;
      right: calc(100% - 20px);
      margin: 2px;
    }
  }
}

//@media screen and (max-width: 991px) {
//  .toggle-switch {
//    transform: scale(0.9);
//  }
//}
//
//@media screen and (max-width: 767px) {
//  .toggle-switch {
//    transform: scale(0.825);
//  }
//}
//
//@media screen and (max-width: 575px) {
//  .toggle-switch {
//    transform: scale(0.75);
//  }
//}