.create_button {
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 24px;
  background-image: url('../images/plus.svg');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.warning_message {
  font: normal normal normal 14px/20px Noto Sans;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    font: normal normal normal 14px/20px Noto Sans;
    margin-bottom: 8px;
  }
}

.form_sub_row {
  margin-top: 8px;

  input {
    margin-right: 8px;
  }

  label {
    font: normal normal normal 14px/20px Noto Sans;
  }
}

.form_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}