.create_button {
  position: fixed;
  left: auto;
  bottom: 20px;
  display: block;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 24px;
  background-image: url('../images/plus.svg');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.question_type_select {
  width: 100% !important;
}

.description {
  font: normal normal normal 12px/20px Hiragino Kaku Gothic ProN;
  color: #707070;
}
