.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  max-width: 280px;
  cursor: pointer;

  div {
    color: #b0b0b0;
    font: normal normal normal 13px/18px Noto Sans;
    display: block;
    vertical-align: middle;
    max-width: 216px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copy_icon {
    display: inline-block;
    content: "";
    width: 15px;
    height: 18px;
    background-image: url('../images/copy.svg');
    background-size: 15px 17px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: text-bottom;
    margin-left: 2px;
  }
}