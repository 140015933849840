// UIKit
$global-primary-background: #5da8d6;
$global-secondary-background: #4cb5ab;
$button-danger-background: #840000;
$global-medium-gutter: 80px;
$global-color: #382d26;
$offcanvas-bar-background: white;
$offcanvas-bar-color-mode: none;
$offcanvas-bar-padding-vertical: 0;
$offcanvas-bar-padding-horizontal: 0;
$offcanvas-bar-width: 328px;
$breakpoint-small: 640px;
$breakpoint-xsmall-max: ($breakpoint-small - 1);

// Moderator
$moderator-background-color: #f7f3f0;
$moderator-brand-color: #b92d00;
$moderator-section-color: #f74d2e;
$moderator-admin-space-info-height: 64px;
$moderator-attendee-header-height: 140px;
$moderator-iphone-footer-height: 120px;
$moderator-scrollbar-width: calc(100vw - 100%);
