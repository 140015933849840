@import 'src/variables';

.container {
  display: flex;
  color: #7d7d7d;
  margin-top: 16px;

  @media (min-width: $breakpoint-small) {
    flex-direction: row;
    justify-content: center;
    font: normal normal normal 14px/23px Noto Sans;
    margin-bottom: 24px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
    align-items: center;
    font: normal normal normal 10px/14px Noto Sans;
    margin-bottom: $moderator-iphone-footer-height;
  }

  .sentence {
    display: inline-block;
  }

  .links {
    display: inline-block;

    a {
      color: #7d7d7d;
      text-decoration: underline;
      margin-left: 8px;

      &:hover {
        color: #7d7d7d;
      }
    }
  }
}
