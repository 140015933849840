.consume_toggle_button {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 3px solid #707070;
  border-radius: 8px;
  padding: 0 8px 0 8px;
  background-color: #aaaaaa;
  height: 30px;
  color: white;
  cursor: pointer;
  font: normal normal normal 14px/19px Noto Sans;

  &_back {
    display: flex;
    flex-direction: row;

    &:before {
      content: "";
      display: inline-block;
      width: 22px;
      height: 18px;
      margin-right: 4px;
      background-image: url("../images/arrow-left.svg");
      transform: rotate(90deg);
      background-size: 22px 18px;
      background-position: center;
    }
  }

  &_consume {
    display: flex;
    flex-direction: row;

    &:before {
      content: "";
      display: inline-block;
      width: 22px;
      height: 18px;
      margin-right: 4px;
      background-image: url("../images/arrow-left.svg");
      transform: rotate(270deg);
      background-size: 22px 18px;
      background-position: center;
    }
  }
}