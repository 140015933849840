@import "../variables.scss";

.space {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-small) {
    margin: 0 64px 24px 64px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding: 0;
    width: 100%;
    margin-bottom: 32px;
  }

  .space_id {
    display: block;
    color: #7d7d7d;
    font: normal normal normal 16px/24px Noto Sans;
  }

  .space_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .space_created_at {
      font: normal normal normal 16px/24px Noto Sans;
      color: #7d7d7d;
      margin-right: 8px;
    }

    .delete_space_column {
      margin-left: auto;
    }
  }

  .space_detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 16px;
    box-shadow: 0 0 6px #00000029;
    cursor: pointer;

    .space_name {
      font: normal normal bold 20px/28px Noto Sans;
      color: #2b2420;
      margin-right: 40px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}

.no_any_spaces_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;

  .black_modrow {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 32px;

    @media (min-width: $breakpoint-small) {
      width: 148px;
      height: 186px;
      background-image: url('../images/taco_gray_w148.svg');
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 70px;
      height: 92px;
      background-image: url('../images/taco_gray_w70.svg');
    }
  }

  .no_any_spaces_message {
    font: normal normal medium 20px/24px Noto Sans;
    color: #7d7d7d;
    text-align: center;

    @media (min-width: $breakpoint-small) {
      max-width: 562px;
    }
  }

}
