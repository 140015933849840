.gear {
  min-width: 17px;
  height: 16px;
  margin-right: 8px;
  background-image: url('../images/cog.svg');
  background-repeat: no-repeat;
  background-size: 17px 16px;
  background-position: center;
  cursor: pointer;
  display: block;
}

.modal_title {
  font: normal normal bold 24px/24px Noto Sans;
}

.copiable_text_container {
  display: flex;
  flex-direction: row;

  &_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border: 1px solid #b0b0b0;
    border-radius: 5px;
    padding: 2px 8px;
    margin-right: 8px;
    height: 32px;
    color: #b0b0b0;
    font: normal normal normal 13px/18px Noto Sans;
    cursor: pointer;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_header {
  display: flex;
  flex-direction: row;
}

.form_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    font: normal normal normal 14px/20px Noto Sans;
    margin-bottom: 8px;
  }
}

.form_sub_row {
  margin-top: 8px;

  input {
    margin-right: 8px;
  }

  label {
    font: normal normal normal 14px/20px Noto Sans;
  }
}

.form_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}