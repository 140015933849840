@import "../variables.scss";

.container {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-small) {
    padding-top: 96px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding-top: 24px;
  }

  .title_container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .title {
      display: block;
      background-repeat: no-repeat;
      cursor: pointer;

      @media (min-width: $breakpoint-small) {
        background-image: url('../images/logo_w338.svg');
        background-repeat: no-repeat;
        background-size: 338px 60px;
        width: 338px;
        height: 60px;
        margin-bottom: 80px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        background-image: url('../images/logo_w338.svg');
        background-repeat: no-repeat;
        background-size: 243px 43px;
        width: 243px;
        height: 43px;
        margin-bottom: 38px;
      }
    }
  }

  .form_container {
    display: flex;

    @media (min-width: $breakpoint-small) {
      flex-direction: row;
      justify-content: center;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
      align-content: flex-start;
    }

    .form_panel_wrapper {
      display: flex;

      @media (min-width: $breakpoint-small) {
        flex-direction: column;
        width: 588px;
        height: 280px;

        &:first-child {
          margin-right: 16px;
        }
      }

      @media (max-width: $breakpoint-xsmall-max) {
        flex-direction: row;
        height: 280px;
        margin: auto 24px;

        &:first-child {
          margin-bottom: 24px;
        }
      }

      .form_panel {
        box-shadow: 0 0 12px #00000042;
        border-radius: 8px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        .form_title {
          font: normal normal bold 24px/33px Noto Sans;
          color: #382d26de;
          margin: 24px auto;
        }

        .form_admin_login_button {
          width: calc(100% - 48px);
          height: 48px;
          font: normal normal bold 18px/24px Noto Sans;

          span {
            line-height: 48px;
          }
        }
      }
    }
  }

  .modrow_container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .modrow {
      background-repeat: no-repeat;

      @media (min-width: $breakpoint-small) {
        margin-top: 96px;
        width: 148px;
        height: 186px;
        background-size: 148px 186px;
        background-image: url('../images/taco_red_w148.svg');
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-top: 56px;
        width: 70px;
        height: 92px;
        background-size: 70px 92px;
        background-image: url('../images/taco_red_w70.svg');
      }
    }
  }
}
