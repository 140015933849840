@import '../variables.scss';

.export_button {
  width: 64px;
  height: 24px;
  font: normal normal normal 12px/18px Noto Sans;
  background-color: white;
  padding: 0 8px;
  border: 1px solid #707070;

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../images/download.svg');
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: text-bottom;
  }

  span {
    line-height: 24px;
    color: black;
  }
}

.target_container {
  display: flex;
  flex-direction: column;

  .target_row {
    display: flex;
    margin-bottom: 16px;

    @media (min-width: $breakpoint-small) {
      flex-direction: row;
      height: 32px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
    }

    .target_type_vote {
      flex: none;
      font: normal normal bold 14px/20px Noto Sans;
      background-color: $global-primary-background;
      color: white;
      width: 88px;
      height: 20px;
      text-align: center;

      @media (min-width: $breakpoint-small) {
        margin-right: 16px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-bottom: 8px;
      }
    }

    .target_type_survey {
      flex: none;
      font: normal normal bold 14px/20px Noto Sans;
      background-color: $global-secondary-background;
      color: white;
      width: 88px;
      height: 20px;
      text-align: center;

      @media (min-width: $breakpoint-small) {
        margin-right: 16px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-bottom: 8px;
      }
    }

    .target_name {
      font: normal normal bold 14px/20px Noto Sans;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;

      @media (min-width: $breakpoint-small) {
        margin-right: 16px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-bottom: 8px;
      }
    }

    .target_download {
      margin-left: auto;
      flex: none;

      button {
        font: normal normal normal 14px/20px Noto Sans;
        width: 128px;
        height: 32px;
        flex: none;
      }
    }
  }

  .target_footer {
    margin-left: auto;
  }
}
