@import "../variables.scss";

.nav {
  background-color: #840000;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (min-width: $breakpoint-small) {
    padding-left: 24px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding: 0;
    width: 100%;
  }

  &_item {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (min-width: $breakpoint-small) {
      width: 144px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 50%;
    }

    a {
      color: white;
      display: inline-block;
      font: normal normal bold 14px/24px Noto Sans;

      span {
        display: inline-block;
        text-align: center;
        line-height: 32px;
      }
    }

    a:hover {
      color: white;
      text-decoration: none;
    }

    &_active {
      border-bottom: 4px solid #f04b2e;
      a {
        opacity: 1;
      }
    }

    &_inactive {
      border-bottom: 4px solid #840000;
      a {
        opacity: 0.73;
      }
    }
  }
}

