.change_button {
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url('../images/user.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
}
