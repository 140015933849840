@import "../variables.scss";

.header {
  background-color: $moderator-brand-color;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    display: block;
    width: 150px;
    height: 26px;
    background-image: url('../images/Moderator.svg');
    background-repeat: no-repeat;
    background-size: 150px 26px;
    margin-left: 16px;
    cursor: pointer;
  }
}
