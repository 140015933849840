@import "../variables.scss";

.container {
  display: flex;
  flex-direction: column;

  .current_section_row {
    background-color: #aea39e;
    padding: 10px 31px 10px 24px;
    color: white;
    font: normal normal normal 18px/27px Noto Sans;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .section_detail_wrapper {
    overflow-y: auto;

    @media (min-width: $breakpoint-small) {
      height: calc(100vh - #{$moderator-attendee-header-height} - 58px);
    }

    &_q_and_a {
      @media (max-width: $breakpoint-xsmall-max) {
        height: calc(100vh - #{$moderator-attendee-header-height} - 82px);
      }
    }
    &_survey {
      @media (max-width: $breakpoint-xsmall-max) {
        height: calc(100vh - #{$moderator-attendee-header-height} - 40px);
      }
    }

    .section_detail_container {
      @media (min-width: $breakpoint-small) {
        margin-left: auto;
        margin-right: auto;
        max-width: 827px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin: 0;
      }

      .modrow {
        display: block;
        width: 70px;
        height: 92px;
        background-image: url('../images/taco_red_w70.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 16px auto 0 auto;
      }
    }
  }

  .section_detail_header {
    display: flex;

    @media (min-width: $breakpoint-small) {
      flex-direction: row;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 8px;
      height: 42px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
      height: 82px;
    }

    &_q_and_a {
      @media (max-width: $breakpoint-xsmall-max) {
        height: 82px;
      }
    }

    &_survey {
      @media (max-width: $breakpoint-xsmall-max) {
        height: 40px;
      }
    }

    &_wrapper {
      @media (min-width: $breakpoint-small) {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 789px;
      }
    }
  }

  .section_detail_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;

    @media (max-width: $breakpoint-xsmall-max) {
      margin-left: 24px;
      margin-right: 24px;
    }

    &_counter {
      @media (min-width: $breakpoint-small) {
        margin-left: 16px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-left: auto;
      }
    }
  }

  .section_name {
    font: normal normal bold 14px/24px Noto Sans;
    margin-right: 8px;
  }

  .section_controls {
    margin-left: auto;

    @media (max-width: $breakpoint-xsmall-max) {
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: white;

      select {
        height: 24px;
        font: normal normal normal 12px/20px Noto Sans;
      }
    }
  }

  .no_any_sections_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $breakpoint-small) {
      padding-top: 187px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      padding-top: 143px;
    }

    .no_any_sections_modrow {
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 32px;

      @media (min-width: $breakpoint-small) {
        width: 148px;
        height: 186px;
        background-image: url('../images/taco_gray_w148.svg');
      }

      @media (max-width: $breakpoint-xsmall-max) {
        width: 70px;
        height: 92px;
        background-image: url('../images/taco_gray_w70.svg');
      }
    }

    .no_any_sections_message {
      font: normal normal medium 20px/24px Noto Sans;
      color: #7d7d7d;
      text-align: center;

      @media (min-width: $breakpoint-small) {
        max-width: 562px;
        margin-bottom: 194px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-bottom: 80px;
      }

      &_edit_nickname_button {
        display: inline-block;
        content: "";
        width: 19px;
        height: 19px;
        background-image: url('../images/user_gray.svg');
        background-size: 19px 19px;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: text-bottom;
      }
    }
  }
}