.export_button {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../images/cloud-download.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
