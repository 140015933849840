.container {
  background-color: #4cb5ab;
  padding: 14px 31px 14px 24px;
  color: white;
  height: 68px;
}

.nickname_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  display: inline-block;
  font: normal normal bold 14px/24px Noto Sans;
  margin-right: 8px;

  span {
    display: inline-block;
    margin: auto 6px;
  }
}

.space_info_row {
  font: normal normal normal 14px/24px Noto Sans;
  color: #e0d4cf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.space_name_row {
  font: normal normal bold 18px/27px Noto Sans;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
