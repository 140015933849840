@import "../variables.scss";

.open_modal_button {
  display: block;
  background-image: url('../images/plus.svg');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 35px;
}

.vote_question_form {
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    &_title {
      font: normal normal bold 24px/24px Noto Sans;
      color: #382d26;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      display: none;
    }
  }

  &_detail {
    display: flex;

    @media (min-width: $breakpoint-small) {
      flex-direction: row;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
    }

    &_post {
      display: flex;
      flex-direction: column;
      flex: 1;

      &_title {
        font: normal normal normal 14px/20px Noto Sans;
        color: #2b2420;
        margin-bottom: 16px;

        @media (max-width: $breakpoint-xsmall-max) {
          display: none;
        }
      }

      &_types {
        font: normal normal bold 18px/20px Noto Sans;
        margin-bottom: 16px;
      }

      &_field {
        font: normal normal normal 16px/24px Noto Sans;
        color: #7d7d7d;
        resize: none;
        margin-bottom: 8px;

        @media (min-width: $breakpoint-small) {
          order: 1;
        }

        @media (max-width: $breakpoint-xsmall-max) {
          order: 2;
        }
      }

      &_message {
        font: normal normal normal 14px/20px Noto Sans;

        @media (min-width: $breakpoint-small) {
          order: 2;
        }

        @media (max-width: $breakpoint-xsmall-max) {
          order: 3;
          margin-bottom: 16px;
        }
      }

      &_buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (min-width: $breakpoint-small) {
          order: 3;
        }

        @media (max-width: $breakpoint-xsmall-max) {
          order: 1;
          margin-bottom: 16px;
        }
      }

      &_button {
        width: 140px;
        margin-left: auto;
      }
    }
  }
}