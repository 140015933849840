@import "../variables.scss";

.fullscreen_container {
  background-color: $moderator-background-color;
  display: flex;
  flex-direction: column;

  .fullscreen_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 40px 24px 40px;
    height: 40px;

    .fullscreen_section_type {
      font: normal normal normal 20px/24px Noto Sans;
      color: #382d26;

      &:before {
        display: inline-block;
        content: "";
        width: 24px;
        height: 24px;
        background-image: url('../images/tag.svg');
        background-repeat: no-repeat;
        background-size: 21px 21px;
        margin-right: 8px;
        vertical-align: text-bottom;
      }
    }

    .fullscreen_control_buttons_row {
      margin-left: auto;
      display: flex;
      flex-direction: row;
    }
  }

  .fullscreen_questions_list_view {
    &_container {
      height: calc(100vh - 104px);
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &_wrapper {
      width: calc(100vw - 226px * 2);
      margin: 0 226px;
    }
  }

  .fullscreen_questions_card_view {
    &_container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000000cc 0 0 no-repeat;
      display: flex;
      flex-direction: column;
    }

    &_header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 16px;
      height: 96px;
    }

    &_close_button {
      width: 64px;
      height: 64px;
      cursor: pointer;
      margin-left: auto;

      &:before {
        content: '';
        display: inline-block;
        width: 64px;
        height: 64px;
        background-image: url('../images/close.svg');
        background-size: contain;
        background-position: center;
      }
    }

    &_arrow_column {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 104px;
      cursor: pointer;
    }

    &_left_arrow {
      display: block;
      width: 82px;
      height: 82px;
      background-image: url('../images/path_187.svg');
      background-size: contain;
    }

    &_right_arrow {
      display: block;
      width: 82px;
      height: 82px;
      background-image: url('../images/path_188.svg');
      background-size: contain;
    }

    &_wrapper {
      overflow-y: auto;
      width: 100%;
      height: calc(100vh - 96px);
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &_question {
    }
  }
}
