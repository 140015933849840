@import "../variables.scss";

.container {
  padding: 20px 33px 10px;
  display: flex;
  flex-direction: column;
  flex: none;
  cursor: pointer;

  &_section_editing {
    background-color: $moderator-background-color;
  }

  .section_info_container {
    display: flex;
    flex-direction: row;
    flex: none;

    .section_open_close_badge {
      font: normal normal bold 14px/20px Noto Sans;
      color: white;
      padding: 1px 8px;
      height: 19px;

      &_open {
        background-color: #f74d2e;
      }

      &_close {
        background-color: #bdbdbd;
      }
    }
  }

  .section_name {
    font: normal normal bold 14px/20px Noto Sans;
    margin: 5px 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  .section_footer {
    display: flex;
    flex-direction: row;

    .section_delete_button {
      cursor: pointer;
      display: inline-block;
      align-self: flex-end;
      //margin-right: 16px;
      margin-left: auto;

      &:before {
        content: "";
        background-image: url('../images/trash.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }

    .section_type {
      font: normal normal normal 14px/20px Noto Sans;
      color: #97908b;

      i {
        margin-right: 5px;
      }
    }
  }

  .section_now_onair {
    display: block;
    margin-left: auto;
    font: normal normal normal 16px/20px Noto Sans;
    background-color: #F74D2E;
    border-radius: 8px;
    color: white;
    padding: 1px 8px;
    height: 19px;
  }
}