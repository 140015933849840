.form_header {
  display: flex;
  flex-direction: row;
}

.modal_title {
  font: normal normal bold 24px/24px Noto Sans;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    font: normal normal normal 14px/20px Noto Sans;
    margin-bottom: 8px;
  }
}

.form_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
