.single_answer_question {
  display: flex;
  flex-direction: column;
  margin: 24px auto;

  label {
    margin-bottom: 24px;
    font: normal normal normal 20px/20px Noto Sans;
    color: #868686;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      flex: none;
    }

    div {
      max-width: 688px;
      margin-left: 8px;
      display: inline-block;
      width: 100%;
      white-space: normal;
    }
  }

  &_answered {
    font: normal normal bold 20px/20px Noto Sans;
    color: #362d27;
  }
}
