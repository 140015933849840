@import "../variables.scss";

.survey_question_form {
  background-color: #fdfbfb;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: none;

  @media (min-width: $breakpoint-small) {
    margin: 0 17px 26px 19px;
    box-shadow: 0 3px 6px #00000042;
    padding: 49px 24px 20px 24px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    margin: 0 0 16px 0;
    padding: 19px 19px 34px 14px;
  }

  &_row {
    margin-bottom: 19px;
    display: flex;
    flex-direction: column;

    label {
      display: inline-block;
      color: #2B2420;
      margin-bottom: 8px;
    }

    textarea {
      height: 140px;
    }

    button {
      margin-left: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .in_progress {
      margin-left: auto;
    }
  }
}
