.exit_fullscreen_button {
  font: normal normal normal 14px/20px Noto Sans;
  width: 160px;
  height: 40px;
  margin-left: 20px;
  background-color: #ff6e6e;
  color: white;
}

.view_mode_button {
  font: normal normal normal 14px/20px Noto Sans;
  width: 200px;
  height: 40px;
  margin-left: 20px;
  background-color: #4cb5ab;
  color: white;

  &_list:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 17px;
    background-image: url('../images/list.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: text-bottom;
    margin-right: 3px;
  }
}
