@import "../variables.scss";

.survey_question {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &_answer_count {
    @media (min-width: $breakpoint-small) {
      margin: 0 24px 14px 24px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 16px 14px 16px;
    }
  }

  &_badge {
    color: white;
    font: normal normal bold 14px/20px Noto Sans;
    padding: 1px 8px;
    min-width: 48px;
    height: 19px;
    margin-right: 8px;
    text-align: center;
    flex: none;

    &_open {
      background-color: #f74d2e;
    }

    &_close {
      background-color: #bdbdbd;
    }
  }

  &_header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media (min-width: $breakpoint-small) {
      margin: 0 24px 14px 24px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 16px 14px 16px;
    }

    &_text {
      font: normal normal bold 16px/22px Noto Sans;
    }
  }

  &_content {
    display: flex;
    background-color: #fdfbfb;
    flex-direction: column;

    &_answered {
      border: 4px solid #8bbeb7;
    }

    @media (min-width: $breakpoint-small) {
      margin: 0 16px 16px 20px;
      box-shadow: 0 3px 6px #00000029;
      padding: 16px 16px 20px 14px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 0 16px 0;
      padding: 19px 19px 24px 14px;
    }

    &_items {
      font: normal normal bold 20px/20px Noto Sans;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: $breakpoint-small) {
        margin-left: 24px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin-left: 16px;
      }
    }

    &_chart_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-top: 2px dashed #f7f3f0;
      padding-top: 16px;
    }

    &_chart {
      display: flex;
      flex-direction: row;
      border: 1px solid #707070;
      justify-content: center;
      align-items: center;
      height: 296px;

      @media (min-width: $breakpoint-small) {
        width: 70%;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        width: 100%;
      }
    }
  }
}

.survey_question_for_admin {
  background-color: #fdfbfb;
  display: flex;
  flex-direction: column;
  flex: none;

  @media (min-width: $breakpoint-small) {
    margin: 0 16px 26px 20px;
    box-shadow: 0 3px 6px #00000029;
    padding: 16px 16px 20px 14px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    margin: 0 0 16px 0;
    padding: 19px 19px 24px 14px;
  }

  &_top {
    display: flex;
    flex-direction: row;
  }

  &_answer_count {
    margin-bottom: 16px;
  }

  &_menu {
    margin-left: auto;

    &_icon {
      background-image: url("../images/more.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      padding-right: 8px;
      cursor: pointer;
    }

    &_container {
      ul {
        margin: 0;
        padding: 0;

        li {
          font: normal normal normal 14px/23px Noto Sans;
          color: #7d7d7d;
          list-style-type: none;
          text-align: center;
          border-bottom: 1px solid #e8e5e3;
          padding-top: 8px;
          padding-bottom: 8px;
          cursor: pointer;

          &:last-child {
            border: none;
          }
        }
      }
    }

    &_delete:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 18px;
      margin-right: 8px;
      background-image: url("../images/trash.svg");
      background-size: contain;
      vertical-align: text-bottom;
    }
  }

  &_header {
    display: flex;
    margin-bottom: 14px;
    flex-direction: row;
    align-items: flex-start;

    &_badge {
      font: normal normal bold 14px/20px Noto Sans;
      color: white;
      padding: 1px 8px;
      height: 19px;
      margin-right: 8px;

      &_open {
        background-color: #f74d2e;
      }

      &_close {
        background-color: #bdbdbd;
      }
    }

    &_text {
      font: normal normal bold 16px/22px Noto Sans;
    }
  }

  &_chart_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &_chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    height: 296px;

    @media (min-width: $breakpoint-small) {
      width: 70%;
      overflow: hidden;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
    }
  }

  &_footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media (min-width: $breakpoint-small) {
      flex-direction: row;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
    }

    &_option {
      background-color: #e8e5e3;
      padding: 8px;

      label > span {
        margin-left: 10px;
        font: normal normal normal 14px/21px Noto Sans;
        cursor: pointer;
      }

      label:first-child {
        margin-right: 16px;
      }

      @media (min-width: $breakpoint-small) {
        flex-direction: row;
        margin-right: 16px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        flex-direction: column;
        margin-bottom: 8px;
      }

      &:last-child {
        @media (min-width: $breakpoint-small) {
          margin-right: 0;
        }

        @media (max-width: $breakpoint-xsmall-max) {
          margin-bottom: 0;
        }
      }
    }
  }
}