.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error_code {
  font: normal normal normal 130px/177px Noto Sans;
  color: #840000;
}

.modrow {
  display: block;
  background-image: url('./images/sorry_w190.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 188px;
  height: 248px;
}

.message {
  margin-top: 24px;
  color: #707070;

  a {
    color: #7D7D7D;

    &:hover {
      color: #7D7D7D;
    }
  }
}