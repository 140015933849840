.input_field {
  width: calc(100% - 48px);
  height: 48px;
  font: normal normal medium 18px/24px Noto Sans;
  margin-bottom: 8px;
}

.join_button {
  width: calc(100% - 48px);
  height: 48px;
  font: normal normal bold 18px/24px Noto Sans;

  span {
    line-height: 48px;
  }
}
