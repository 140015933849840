@import "../variables.scss";

.vote_question {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: none;

  &_mine {
    border: 2px solid #4cb5ab;
  }

  &_clickable {
    cursor: pointer;
  }

  &_card_view {
    width: 734px;
    min-height: 529px;
    position: relative;
  }

  @media (min-width: $breakpoint-small) {
    margin: 0 16px 26px 20px;
    box-shadow: 0 3px 6px #00000029;
    padding: 16px 16px 20px 14px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    margin: 0 0 16px 0;
    padding: 19px 19px 34px 14px;
  }

  &_selected {
    &_container {
      display: flex;
      flex-direction: row;

      @media (max-width: $breakpoint-xsmall-max) {
        display: none;
      }
    }

    &_checkbox {
      margin-top: 0;
      width: 25px;
      height: 25px;
      margin-right: 16px;
    }
  }

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font: normal normal normal 14px/19px Noto Sans;
    color: #707070;
    margin-bottom: 14px;

    &_card_view {
      font: normal normal bold 26px/36px Noto Sans;
    }

    &_vote_question_nickname {
      margin-left: 8px;
    }

    &_vote_question_datetime {
      margin-left: 8px;
    }

    &_consume_badge {
      margin-left: auto;
      font-size: 14px;
      color: white;
      background-color: #4cb5ab;
      padding: 3px 8px 2px 8px;
    }

    &_vote_question_type {
      background-color: #aaa;
      color: white;
      width: 64px;
      height: 20px;
      text-align: center;
      padding: 3px 8px 2px 8px;
      font: normal normal normal 14px/19px Noto Sans;
    }
  }

  &_text {
    font: normal normal bold 18px/27px Noto Sans;
    letter-spacing: 0.2px;
    color: #2B2420;
    margin-bottom: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    &_card_view {
      font: normal normal bold 26px/42px Noto Sans;
      letter-spacing: 0.26px;
    }
  }

  &_footer {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 27px;
    align-items: center;

    &_card_view {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    &_vote_count {
      font: normal normal bold 18px/27px Noto Sans;

      &:before {
        content: "";
        background-image: url('../images/heart.svg');
        width: 19px;
        height: 16px;
        display: inline-block;
        margin-right: 6px;
      }

      &_clickable {
        cursor: pointer;
      }

      &_voted:before {
        background-image: url('../images/heart_green.svg');
      }
    }

    &_delete_button {
      cursor: pointer;
      display: inline-block;
      align-self: flex-end;
      margin-right: 16px;

      &:before {
        content: "";
        background-image: url('../images/trash.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }
  }
}