@import "../variables.scss";

.bulk_control {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 0 8px 0 8px;
  background-color: white;
  height: 40px;
  color: black;
  cursor: pointer;

  input {
    width: 25px;
    height: 25px;
    margin-right: 16px;
    margin-top: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font: normal normal normal 14px/24px Noto Sans;

    &:after {
      content: "";
      width: 25px;
      height: 25px;
      display: inline-block;
      background-image: url("../images/chevron-down.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 25px 25px;
    }
  }

  &_menu_container {
    ul {
      margin: 0;
      padding: 0;

      li {
        font: normal normal normal 14px/23px Noto Sans;
        color: #7d7d7d;
        list-style-type: none;
        text-align: center;
        border-bottom: 1px solid #e8e5e3;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;

        &:last-child {
          border: none;
        }
      }
    }
  }
}
