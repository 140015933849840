@import "../variables.scss";

.content_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;

  .content_wrapper {
    height: calc(100vh - 104px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .content {
      @media (min-width: $breakpoint-small) {
        margin: 32px 64px auto 24px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        margin: 32px 16px auto 16px;
      }
    }

    .spaces_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 32px;

      &_title {
        display: inline-block;
        font: normal normal bold 24px/24px Noto Sans;
        color: #382d26;
        margin-right: 20px;
      }

      select {
        margin-left: auto;
      }
    }
  }
}
