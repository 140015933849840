@import "../variables.scss";

.container {
    flex: 1;
    display: flex;

    .section_list {
        display: flex;
        align-items: stretch;
    }

    .section_wrapper {
        position: relative;
    }

    .section_header {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        left: 0;
        right: 17px;
        height: 60px;
        padding-left: 24px;
        padding-right: 16px;
        background-color: rgba(247, 243, 240, 0.8);

        @media (max-width: $breakpoint-xsmall-max) {
            margin-bottom: 8px;
        }

        &_presentation_mode_button {
            &:before {
                content: "";
                display: inline-block;
                width: 17px;
                height: 17px;
                margin: 0 5px 0 0;
                background-image: url('../images/desktop.svg');
                background-size: contain;
                vertical-align: text-bottom;
            }
        }

        &_presentation_mode_menu {
            margin-left: auto;

            &_in_full_screen:before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                background-image: url("../images/fullscreen.svg");
                background-size: contain;
                vertical-align: text-bottom;
            }

            &_in_page:before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                background-image: url("../images/pageview.svg");
                background-size: contain;
                vertical-align: text-bottom;
            }
        }

        &_presentation_mode_menu_container {
            ul {
                margin: 0;
                padding: 0;

                li {
                    font: normal normal normal 14px/23px Noto Sans;
                    color: #7d7d7d;
                    list-style-type: none;
                    text-align: center;
                    border-bottom: 1px solid #e8e5e3;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    cursor: pointer;

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .section_detail_container {
        height: calc(100vh - #{$moderator-admin-space-info-height});
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .section_info {
            display: flex;
            align-content: baseline;

            @media (min-width: $breakpoint-small) {
                flex-direction: row;
                align-items: center;
                padding: 0 19px 0 20px;
                margin-top: 91px;
                margin-bottom: 27px;
            }

            @media (max-width: $breakpoint-xsmall-max) {
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                width: 100%;
                margin-top: 83px;
            }

            &_section_type {
                font: normal normal normal 14px/21px Noto Sans;
                color: #382d26;
                background-color: white;
                display: flex;
                flex-direction: row;
                height: 100%;
                justify-content: flex-start;
                align-items: center;
                padding: 8px;

                &:before {
                    content: "";
                    display: inline-block;
                    background-image: url('../images/tag.svg');
                    background-size: contain;
                    width: 20px;
                    height: 20px;
                    margin: 0 5px 0 0;
                    vertical-align: text-bottom;
                }

                @media (max-width: $breakpoint-xsmall-max) {
                    width: 100%;
                }
            }

            &_name {
                font: normal normal bold 18px/27px Noto Sans;
                color: #2b2420;
                background-color: white;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                height: 100%;
                flex: 1;
                padding: 8px;

                @media (min-width: $breakpoint-small) {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                @media (max-width: $breakpoint-xsmall-max) {
                    width: 100%;
                }
            }

            &_controls {
                background-color: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                height: 100%;

                @media (min-width: $breakpoint-small) {
                    padding: 8px;
                }

                @media (max-width: $breakpoint-xsmall-max) {
                    width: 100%;
                    padding-right: 8px;
                    padding-bottom: 8px;
                }
            }

            &_footer_left_counter {
                margin-left: 16px;
            }
        }

        &_footer {
            @media (min-width: $breakpoint-small) {
                display: none;
            }

            @media (max-width: $breakpoint-xsmall-max) {
                height: $moderator-iphone-footer-height;
                flex: none;
            }
        }

        .no_current_section_container {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $breakpoint-small) {
                padding-top: 264px;
            }

            @media (max-width: $breakpoint-xsmall-max) {
                padding-top: 200px;
            }

            .modrow {
                display: block;
                background-repeat: no-repeat;
                background-size: contain;
                margin-top: 32px;
                width: 70px;
                height: 92px;
                background-image: url('../images/taco_red_w70.svg');
            }

            .no_current_section_message {
                font: normal normal normal 20px/24px Noto Sans;
                color: #7d7d7d;
                text-align: center;

                @media (max-width: $breakpoint-xsmall-max) {
                    width: calc(100vw - 48px);
                    margin: auto 24px;
                }

                .from_section_button {
                    display: inline-block;

                    @media (min-width: $breakpoint-small) {
                        display: none;
                    }

                    @media (max-width: $breakpoint-xsmall-max) {
                        &:before {
                            display: inline-block;
                            content: "";
                            width: 20px;
                            height: 20px;
                            background-color: $moderator-section-color;
                            background-image: url('../images/list.svg');
                            background-size: 16px 11px;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                        &:after {
                            display: inline-block;
                            content: "から";
                        }
                    }
                }
            }
        }

        .consume_tab {
            &_container {
                display: flex;
                flex-direction: row;
                margin: 16px 19px 0 20px;
                padding-left: 0;
                position: relative;

                &:after {
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 1px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    background-color: #D1D0D0;
                }
            }

            &_item {
                overflow: hidden;
                position: relative;
                z-index: 0;
                width: 200px;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font: normal normal normal 14px/24px Noto Sans;
                cursor: pointer;

                &_active {
                    z-index: 11;
                    background-color: #f7f3f0;
                    border-top: 1px solid #D1D0D0;
                    border-left: 1px solid #D1D0D0;
                    border-right: 1px solid #D1D0D0;
                    color: #382D26;
                }

                &_inactive {
                    background-color: #AAAAAA;
                    color: #FFFFFF;
                }
            }

            &_content {
                margin: 0 19px 16px 20px;
                padding-top: 16px;
                border-bottom: 1px solid #D1D0D0;
                border-left: 1px solid #D1D0D0;
                border-right: 1px solid #D1D0D0;

                &_header {
                    margin: 0 19px 16px 20px;
                    display: flex;
                    flex-direction: row;

                    &_control_buttons {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}
