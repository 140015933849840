@import '../variables.scss';

.counter_badge_container {
  width: 73px;
  height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    font: normal normal bold 10px/10px Noto Sans;
  }

  &_primary {
    color: #f99583;
  }

  &_secondary {
    color: gray;
  }
}
