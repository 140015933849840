@import "../variables.scss";

.container {
  background-color: white;
  flex-basis: 100%;
  height: calc(100vh - #{$moderator-admin-space-info-height});
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;

  .header {
    height: 64px;
    background-color: $moderator-section-color;
    color: white;
    display: flex;
    justify-content: center;

    i, span {
      display: flex;
      align-items: center;
      height: 64px;
    }
  }

  .no_any_sections_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $breakpoint-small) {
      padding-top: 264px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      padding-top: 200px;
    }

    .black_modrow {
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 32px;
      width: 70px;
      height: 92px;
      background-image: url('../images/taco_gray_w70.svg');
    }

    .no_any_sections_message {
      font: normal normal bold 14px/20px Noto Sans;
      color: #382d26;
      text-align: center;
    }
  }
}
