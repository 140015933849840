.create_button {
  width: 80px;
  height: 24px;
  font: normal normal normal 12px/18px Noto Sans;
  background-color: white;
  padding: 0 8px;
  border: 1px solid #707070;
  margin-left: 8px;

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../images/download.svg');
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: text-bottom;
  }

  span {
    line-height: 24px;
    color: black;
  }
}
