@import "../variables.scss";

.container {
  background-color: white;
  box-shadow: 0 0 12px #00000042;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-small) {
    width: 588px;
    //height: 326px;
    padding: 24px 24px 24px 24px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    width: calc(100vw - 48px);
    margin: 24px;
    //height: 278px;
    padding: 24px 24px 24px 24px;
  }

  label {
    font: normal normal normal 14px/20px Noto Sans;
    color: #2b2420;
    margin-bottom: 8px;
  }

  .field_row {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .warning {
    display: inline-block;
    font: normal normal normal 14px/20px Noto Sans;
    height: 20px;
  }

  input {
    height: 48px;
  }

  button {
    font: normal normal normal 20px/24px Noto Sans;
    height: 48px;

    @media (min-width: $breakpoint-small) {
      width: 280px;
      margin-left: auto;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
    }
  }
}
