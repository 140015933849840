@import "../variables.scss";

.content_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;

  .content_wrapper {
    height: calc(100vh - 104px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (min-width: $breakpoint-small) {
        margin-top: 144px;
      }
    }
  }
}