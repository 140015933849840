@import '../variables.scss';

.container {
  display: flex;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  background: #5DA8D6 0% 0% no-repeat padding-box;
  color: white;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-small) {
    height: 40px;
    font: normal normal normal 14px/23px Noto Sans;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    height: 51px;
    font: normal normal normal 10px/14px Noto Sans;
  }

  .sentence {
    margin-left: 8px;
    margin-right: 8px;
  }

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: white;
    }
  }

  button {
    background: #5DA8D6 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 0 8px 0 8px;
    flex: none;
    margin-right: 8px;

    @media (min-width: $breakpoint-small) {
      font: normal normal normal 14px/23px Noto Sans;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      font: normal normal normal 10px/14px Noto Sans;
    }
  }
}