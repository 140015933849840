@import "./variables.scss";

@import "node_modules/uikit/src/scss/variables-theme.scss";
@import "node_modules/uikit/src/scss/mixins-theme.scss";

@mixin hook-form-single-line() {
  border-radius: 10px;
  background-color: white;
}

@mixin hook-button() {
  border-radius: 10px;
}

@mixin hook-offcanvas-bar() {
  top: $moderator-admin-space-info-height;
}

@mixin hook-dropdown() {
  border-radius: 10px;
  box-shadow: 0 0 6px #00000029;
  padding: 0;

  &:before {
    content: "";
    position: absolute;
    top: -24px;
    margin-left: -15px;
    border: 12px solid transparent;
    border-bottom: 12px solid #fff;
    z-index: 2;
  }
}

.dropdown-pos-30 {
  &:before {
    left: 30%;
  }
}

.dropdown-pos-90 {
  &:before {
    left: 90%;
  }
}

@import "node_modules/uikit/src/scss/uikit-theme.scss";

html {
  height: 100%;
  background-color: $moderator-background-color;

  body {
    padding: 0;
    min-height: 100%;
    height: 100%;
  }
}

#root {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.uk-modal-dialog .uk-modal-body h2 {
  font: normal normal bold 24px/24px Noto Sans;
}

select {
  box-sizing: border-box;
  margin: 0;
  padding: 0 20px 0 10px;
  background-color: white;
  color: #382d26;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 10px;
  font: normal normal normal 14px/20px Noto Sans;

  &:focus {
    outline: none;
    color: #382d26;
    border-color: #5da8d6;
  }
}
