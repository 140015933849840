@import "../variables.scss";

.container {
  background-color: #b92d00;
  height: $moderator-admin-space-info-height;
  display: flex;
  flex-direction: row;

  .space_info_container {
    display: flex;
    align-items: center;

    @media (min-width: $breakpoint-small) {
      width: calc(100% * 1 / 3.001);
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: calc(100% - 64px);
    }

    .back_button {
      height: 100%;
      min-width: 48px;
      background-image: url('../images/arrow-left.svg');
      background-repeat: no-repeat;
      background-size: 17px 16px;
      background-position: center;
      cursor: pointer;
      display: block;
    }

    .space_info_area {
      overflow: hidden;
      padding: 8px 8px;

      .space_name {
        color: white;
        font: normal normal bold 18px/24px Noto Sans;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .space_created_at {
        color: white;
        font: normal normal normal 12px/30px Noto Sans;
      }
    }
  }

  .copiable_text_list {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 2px 8px;
    margin-right: 8px;
    height: 32px;
    color: #b0b0b0;
    font: normal normal normal 13px/18px Noto Sans;
    cursor: pointer;
  }

  .header_space_info {
    font: normal normal normal 12px/16px Noto Sans;
    color: #7d7d7d;

    &_detail {
      display: flex;
      flex-direction: column;
      padding: 16px;

      &_item {
        margin-bottom: 8px;
      }
    }
  }

  .toggle_section_list {
    margin-left: auto;
    background-color: $moderator-section-color;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 64px;
    height: 100%;
    background-image: url('../images/list.svg');
    background-repeat: no-repeat;
    background-size: 16px 11px;
    background-position: center;
    cursor: pointer;
  }

  .open_attendee_page_button {
    width: 200px;
    height: 32px;
    background: #4cb5ab 0% 0% no-repeat padding-box;
    border: 4px solid white;
    border-radius: 12px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    span {
      display: inline-block;
      font: normal normal bold 14px/19px Noto Sans;
      color: white;
    }
  }
}

.user_statistics_header {
  display: flex;
  flex-direction: row;
}

.modal_title {
  font: normal normal bold 24px/24px Noto Sans;
}

.user_statistics_container {
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    flex-direction: row;

    div.label {
      font: normal normal normal 14px/20px Noto Sans;
    }

    div.value {
      font: normal normal normal 14px/20px Noto Sans;
      margin-left: auto;
    }
  }
}

.user_statistics_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
